import React, {useState} from 'react';
import {observer} from "mobx-react";
import {users} from '../../api/users'
import {toast} from "react-toastify";
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import {useRouter} from "../../helpers/router";

const Register = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordAgain, setPasswordAgain] = useState<string>('')
    const [passwordAgainError, setPasswordAgainError] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const router = useRouter()

    const onRegister = (event: any) => {
        event.preventDefault();
        setLoading(true)

        if (email === undefined) {
            setEmailError(t('You need to provide your emailaddress'))
            return
        }

        if (password === undefined) {
            setPasswordError(t('You need to provide a password'))
            return
        }

        // handle in userActions.register()
        users.register({email: email, password: password})
            .then(response => {
                setLoading(false)
                toast.success(t('Great job!'))

                return router.push(`/registration-success/${email}`)
            })
            .catch((error: any) => {
                console.error(error)
                setLoading(false)

                const response = error.response.data
                if (response.code === 500) {
                    setEmailError(response.message)
                    return
                }

                // const errorMessage = response.data.error.message
                // if (errorMessage.email) {
                //     for (const [, message] of Object.entries(errorMessage.email)) {
                //         // setEmailError(`Rule violated: ${cause}. Error message: ${message}`)
                //         setEmailError(message)
                //     }
                // }

                // if (errorMessage.server) {
                //     setEmailError(errorMessage.server)
                // }
            })
    };

    return (
        <div className='container'>
            <form className='col-md-8'>
                <h1>{t('Register with the FunnelMonitor')}</h1>
                <p>{t('Fill out your information below to create an account and start monitoring your funnels')}</p>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">E-mail adres</label>
                    <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        aria-describedby="emailHelp"
                        placeholder="Waar kan ik de logingegegevens heen mailen?"
                    />
                    {(emailError) && (
                        <div className="alert alert-warning" role="alert">{emailError}</div>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="password">{t('Password')}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                            setPasswordAgainError('')
                            setPassword(e.target.value)
                            if (passwordAgain !== '' && e.target.value !== passwordAgain) {
                                setPasswordAgainError(t('passwords do not match'))
                            }
                        }}
                    />
                    {(passwordError) && (
                        <Alert variant='warning'>{passwordError}</Alert>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="passwordAgain">{t('Password again')}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="passwordAgain"
                        name="passwordAgain"
                        value={passwordAgain}
                        onChange={(e) => {
                            setPasswordAgainError('')
                            setPasswordAgain(e.target.value)
                            console.log(`test: ${e.target.value} === ${password}`)
                            if (e.target.value !== password) {
                                setPasswordAgainError(t('passwords do not match'))
                            }
                        }}
                    />
                    {(passwordAgainError) && (
                        <Alert variant='warning'>{passwordAgainError}</Alert>
                    )}
                </div>

                <button className="btn btn-primary btn-block" type="submit" onClick={onRegister}>
                    {loading && <Spinner animation={"border"}/>}
                    {!loading && t('Send me the login credentials')}
                </button>
            </form>
        </div>
    )
}

export default observer(Register)
