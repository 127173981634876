import axios from 'axios'
import persistentStore from "../store/persistentStore";

const projectRequests = () => {

    const getAll = async () => {
        const path = `/projects`

        return axios.get(persistentStore.apiUrl + path, persistentStore.authHeader)
            .then(response => response.data.projects)
    };

    return {
        getAll,
    }
}

export const projects = projectRequests()
