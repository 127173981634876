import React from 'react';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTired} from "@fortawesome/free-regular-svg-icons/faTired";
import {faFrown} from "@fortawesome/free-regular-svg-icons/faFrown";
import {faMeh} from "@fortawesome/free-regular-svg-icons/faMeh";
import {faMehBlank} from "@fortawesome/free-regular-svg-icons/faMehBlank";
import {faSmile} from "@fortawesome/free-regular-svg-icons/faSmile";
import {faGrinBeam} from "@fortawesome/free-regular-svg-icons/faGrinBeam";
import {IMonthResults} from "../../interfaces/IMonthResults";
import './SmileyResult.scss'
import persistentStore from "../../store/persistentStore";

type SmileyResultProps = {
    results: IMonthResults
}

const SmileyResult = (props: SmileyResultProps) => {
    const costs: number = props.results.costs_in_cents
    const results: number = props.results.fe_sales * (persistentStore.project?.fe_price_in_cents||0)

    const differencePercentage: number = results/costs
    let score: string = 'lowest'
    let iconToUse = faTired

    const emptyResults = (results: IMonthResults) => {
        const isEmpty = (
            results.clicks === 0
            && results.fe_sales === 0
        )

        return isEmpty
    }

    switch(true){
        case emptyResults(props.results):
            score = 'none'
            iconToUse = faMehBlank
            break
        case differencePercentage >= 1.3:
            score = 'highest'
            iconToUse = faGrinBeam
            break
        case differencePercentage > 1:
            score = 'high'
            iconToUse = faSmile
            break
        case differencePercentage === 1:
            score = 'equal'
            iconToUse = faMeh
            break
        case differencePercentage < 1:
            score = 'low'
            iconToUse = faFrown
            break
        case differencePercentage < 1.3:
           score = 'lowest'
            iconToUse = faTired
            break
    }

    return (
        <>
            <Row>
                <FontAwesomeIcon size={"8x"} className={score} icon={iconToUse} />
            </Row>
            <Row className='mt-3'>
                <Col>
                    <FontAwesomeIcon className='mr-1' icon={faTired} />
                    <FontAwesomeIcon className='mr-1' icon={faFrown} />
                    <FontAwesomeIcon className='mr-1' icon={faMeh} />
                    <FontAwesomeIcon className='mr-1' icon={faSmile} />
                    <FontAwesomeIcon className='mr-1' icon={faGrinBeam} />
                </Col>
            </Row>
        </>
    )
}

export default SmileyResult;
