import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import moment from "moment/moment";
import uiStore from "../../store/uiStore";
import {observer} from "mobx-react";

const PeriodSwitcher = (props: any) => {
    const currentPeriod = moment(uiStore.period, uiStore.periodDateFormat)

    const shiftNextMonth = () => {
        const nextMonth = currentPeriod.add(1, 'M')
        const nextPeriod: string = nextMonth.format(uiStore.periodDateFormat)

        uiStore.setPeriod(nextPeriod)
    }

    const shiftPreviousMonth = () => {
        const previousMonth = currentPeriod.subtract(1, 'M')
        const previousPeriod: string =  previousMonth.format(uiStore.periodDateFormat)

        uiStore.setPeriod(previousPeriod)
    }

    return (
        <div {...props}>
            <span className='mr-2' onClick={shiftPreviousMonth} style={{cursor: "pointer"}}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </span>

            {moment(uiStore.period, 'YYYY-M-D').format(uiStore.periodDateFormat)}

            <span className='ml-2' onClick={shiftNextMonth} style={{cursor: "pointer"}}>
                <FontAwesomeIcon icon={faArrowRight}/>
            </span>
        </div>
    )
}

export default observer(PeriodSwitcher);
