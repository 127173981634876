import React from 'react';
import uiStore from "../../store/uiStore";
import {observer} from "mobx-react";
import styled from "styled-components";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PageElement = styled.div`
        border: 2px solid black;
        text-align: center;
        padding: 7px 5px;
        width: 75px;
        height: 100px;
    `

type PageProps = {
    name: string,
    clicks: number,
    conversion?: number,
    actionName?: string,
    last?: boolean
}

const Page = (props: PageProps) => {

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div>
                <div>
                    <PageElement>
                        {props.name}
                    </PageElement>
                </div>
                <div>{(props.clicks).toLocaleString(uiStore.locale, uiStore.fixedNumberOptions)} {props.actionName || 'clicks'}</div>
            </div>

            {!props.last && (
                <div className='ml-3 mr-3'>
                    <div>{(props.conversion || 0).toLocaleString(uiStore.locale, uiStore.percentageOptions)}</div>
                    <div style={{justifyContent: "center", alignItems: "center"}}>
                        <div style={{flexShrink: 1}}>
                            <FontAwesomeIcon size={"5x"} icon={faLongArrowAltRight} width={'2rem'} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default observer(Page)
