import React from 'react';
import {IMonthResults} from "../../interfaces/IMonthResults";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import uiStore from "../../store/uiStore";
import persistentStore from "../../store/persistentStore";
import {observer} from "mobx-react";

type ResultsTableProps = {
    results: IMonthResults[],
}

const ResultsTable = (props: ResultsTableProps) => {
    const {t} = useTranslation()
    const columns = [
        {
            name: t('Date'),
            selector: 'date',
            sortable: true,
            grow: 1,
        },
        {
            name: t('Impressions'),
            selector: 'impressions',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => result.impressions.toLocaleString(uiStore.locale, uiStore.fixedNumberOptions)
        },
        {
            name: t('Conversion Impressions'),
            selector: 'conversionImClicks',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => (result.conversion_im_clicks).toLocaleString(uiStore.locale, uiStore.percentageOptions)
        },
        {
            name: t('Clicks'),
            selector: 'clicks',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => result.clicks.toLocaleString(uiStore.locale, uiStore.fixedNumberOptions)
        },
        {
            name: t('Conversion advertisements'),
            selector: 'conversionAdsLM',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => (result.leadmagnet_hits/result.clicks).toLocaleString(uiStore.locale, uiStore.percentageOptions)
        },
        {
            name: t('Lead Magnet downloads/trials'),
            selector: 'leadmagnet_hits',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => result.leadmagnet_hits.toLocaleString(uiStore.locale, uiStore.fixedNumberOptions)
        },
        {
            name: t('Conversion Lead Magnet'),
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => {
                return (result.fe_sales/result.leadmagnet_hits).toLocaleString(uiStore.locale, uiStore.percentageOptions)
            }
        },
        {
            name: t('Cost per Acquisition'),
            selector: 'cpa',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => {
                return (result.cpa/100).toLocaleString(uiStore.locale, uiStore.moneyOptions)
            }
        },
        {
            name: t('Cost per Click'),
            selector: 'cpc',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => {
                return (result.cpc/100).toLocaleString(uiStore.locale, uiStore.moneyOptions)
            }
        },
        {
            name: t('Return on Ad Spend'),
            selector: 'roas',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => {
                return (result.roas).toLocaleString(uiStore.locale, uiStore.moneyOptions)
            }
        },
        {
            name: t('FrontEnd Sales'),
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => {
                const frontendPriceInCents: number = persistentStore.project?.fe_price_in_cents || 0
                const revenueInCents: number = frontendPriceInCents * (result.fe_sales || 0)
                const floatResult: number = revenueInCents / 100

                return floatResult.toLocaleString(uiStore.locale, uiStore.moneyOptions)
            }
        },
        {
            name: t('Advertising costs'),
            selector: 'costs_in_cents',
            sortable: true,
            grow: 1,
            cell: (result: IMonthResults) => (result.costs_in_cents/100).toLocaleString(uiStore.locale, uiStore.moneyOptions)
        },
        {
            name: t('Result (= sales - costs)'),
            grow: 1,
            cell: (result: IMonthResults) => {
                const frontendPriceInCents: number = persistentStore.project?.fe_price_in_cents || 0
                const revenueInCents: number = frontendPriceInCents * (result.fe_sales || 0)
                const resultInCents: number = revenueInCents - result.costs_in_cents
                const floatResult: number = resultInCents / 100

                return floatResult.toLocaleString(uiStore.locale, uiStore.moneyOptions)
            }
        },
    ]

    const sortedTable = props.results.sort((a: IMonthResults, b: IMonthResults) => b.date.localeCompare(a.date))

    const onRowClicked = (resultClicked: IMonthResults) => {
        uiStore.setPeriod(resultClicked.date)
    }

    return (
        <DataTable
            columns={columns}
            data={sortedTable}
            highlightOnHover
            noHeader={true}
            subHeader={false}
            pointerOnHover={true}
            onRowClicked={(result: IMonthResults) => onRowClicked(result)}
        />
    )
}

export default observer(ResultsTable);
