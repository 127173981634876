import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import persistentStore from "../store/persistentStore";

interface PrivateRouteProps {
    // component: JSX.Element; // passed as prop
    component: any,
    exact: boolean,
    path: string,
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;

    return (
        <Route {...rest} render={(routeProps) => {
            // do the authorization. When no auth.data available, go to /login
            return persistentStore.isLoggedIn
                ? <Component {...routeProps} /> // add the routeProps so the component can access them
                : <Redirect to={{ pathname: '/login'}} />
        }}/>
    );
};

export default PrivateRoute;
