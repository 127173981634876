import React from 'react';
import {IMonthResults} from "../../interfaces/IMonthResults";
import uiStore from "../../store/uiStore";
import {observer} from "mobx-react";
import Page from "./Page";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLevelUpAlt} from "@fortawesome/free-solid-svg-icons/faLevelUpAlt";
import persistentStore from "../../store/persistentStore";

type FuelFunnelProps = {
    results: IMonthResults,
}

const FuelFunnel = (props: FuelFunnelProps) => {
    const {results} = props
    const fePrice: number = (persistentStore.project?.fe_price_in_cents || 0) / 100

    return (
        <div style={{display: "flex", flexDirection: 'column'}}>
            <div style={{display: "flex", flexDirection: 'row'}}>
                <div className='mr-3'>
                    Advertentiekosten: {((results.costs_in_cents / 100)).toLocaleString(uiStore.locale, uiStore.moneyOptions)}
                </div>
                <Page
                    name={'Ads'}
                    clicks={results.clicks}
                    actionName={'clicks'}
                    conversion={results.conversion_ads_lm}
                />
                <Page
                    name={'LM'}
                    clicks={results.leadmagnet_hits}
                    actionName={'trials'}
                    conversion={results.conversion_lm_fe}
                />
                <Page
                    name={'FE'}
                    clicks={results.fe_sales}
                    actionName={'verkopen'}
                    // conversion={(results.frontEnd*results.frontEndPrice).toLocaleString(uiStore.locale, uiStore.moneyOptions)}
                    last={true}
                />
            </div>

            <div>

                <div style={{display: "flex", flexDirection: 'row', marginTop: '10px'}}>
                    <FontAwesomeIcon
                        size={"5x"}
                        icon={faLevelUpAlt}
                        style={{transform: 'scaleX(-1)', marginLeft: '7rem'}}
                    />

                    <div style={{borderBottom: '12px solid black', width: '440px'}}></div>
                    <div style={{borderRight: '12px solid black'}}></div>
                </div>

                <div style={{textAlign: 'center', paddingRight: '20rem'}}>
                    {`${results.fe_sales || 0} x ${fePrice.toLocaleString(uiStore.locale, uiStore.moneyOptions)} = `}
                    {((results.fe_sales || 0) * fePrice).toLocaleString(uiStore.locale, uiStore.moneyOptions)}
                </div>
            </div>
        </div>
    )
}

export default observer(FuelFunnel);
