import {makeAutoObservable} from 'mobx'
import moment from "moment/moment";
import {IMonthResults} from "../interfaces/IMonthResults";

class UiStore {
    showEditCourse: boolean = false
    showDeleteCourse: boolean = false
    showAddLesson: boolean = false
    showManageMembers: boolean = false
    periodDateFormat = 'YYYY-MM'
    period: string = moment().format(this.periodDateFormat)
    results: IMonthResults[] =  []
    emptyResults: IMonthResults = {
        id: '',
        notes: '',
        impressions: 0,
        project_id: '',
        costs_in_cents: 0,
        clicks: 0,
        leadmagnet_hits: 0,
        fe_sales: 0,
        be_sales: 0,
        conversion_im_clicks: 0,
        conversion_ads_lm: 0,
        conversion_lm_fe: 0,
        roas: 0,
        cpa: 0,
        cpc: 0,
        date: this.period
    }

    /**
     * Returns the stats or empty stats
     */
    get currentResult() {
        const currentResult = this.results.find((result: IMonthResults) => result.date === uiStore.period)

        if(! currentResult){
            return Object.assign({}, this.emptyResults, {date: uiStore.period})
        }

        return currentResult
    }

    locale: string = 'nl-NL'
    // for options, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    moneyOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    moneyNoSymbol = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'decimal',
    }
    moneyNoDecimals = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
    }
    fixedNumberOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'decimal',
    }
    percentageOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'percent',
    }

    constructor() {
        makeAutoObservable(this)
    }

    setLocale(locale: string){
        this.locale = locale
    }

    setPeriod(newPeriod: string){
        this.period = newPeriod
    }

    setShowDeleteCourse(value: boolean){
        this.showDeleteCourse = value
    }

    setShowEditCourse(value: boolean){
        this.showEditCourse = value
    }

    setShowAddLesson(value: boolean){
        this.showAddLesson = value
    }

    setShowManageMembers(value: boolean){
        this.showManageMembers = value
    }
}

const uiStore = new UiStore()
export default uiStore
