import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Switch} from './helpers/router'
import {observer} from "mobx-react";
import './helpers/i18n'
import i18next from "i18next";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";

// change the language
i18next.changeLanguage('nl')

const App = () => {
    const {t} = useTranslation()

    return (
        <section id="container">
            {/*<Header/>*/}

            {/*<aside>*/}
            {/*    <Sidebar/>*/}
            {/*</aside>*/}

            <section id="main-content">
                <section className="wrapper">
                    <Switch>
                        <PrivateRoute exact path='/' component={Dashboard}/>
                        <PrivateRoute exact path='/dashboard' component={Dashboard}/>
                        <Route component={() => <div>{t('Page not found')}</div>}/>
                    </Switch>
                </section>
                {/*<Footer/>*/}
                {/*<BottomNav />*/}
            </section>
        </section>
    )
}

export default observer(App);
