import React, {Suspense} from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "react-query";
import {Router, history, Switch, Route} from "./helpers/router";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import persistentStore from "./store/persistentStore";
import {users} from "./api/users";
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from "./pages/PasswordReset/ResetPassword";
import {Login} from "./pages/Login/Login";
import RequestPasswordReset from "./pages/PasswordReset/RequestResetPassword";
import Routes from "./Routes";

const queryClient = new QueryClient()

if (process.env.REACT_APP_API_URL) {
    persistentStore.setApiUrl(process.env.REACT_APP_API_URL)
}

toast.configure({
    autoClose: 10000,
    draggable: false,
    position: toast.POSITION.TOP_RIGHT
})

// catch network errors
axios.defaults.timeout = 15000
axios.interceptors.response.use(
    (response: any) => {
        return response
    },
    error => {

        console.error('interceptor error', error)
        let message = ''

        if (error.code === 'ECONNABORTED') {
            toast.error(error.message)
            return Promise.reject(error)
        }

        console.error(error.response)
        if (error.response !== undefined) {
            switch (error.response.status) {
                case 502:
                    message = 'System is starting up, please try again in a minute'
                    toast.error(message)
                    break
                case 401:
                    message = 'Login verlopen, log opnieuw in alstublieft'
                    // prevent too many messages
                    toast.clearWaitingQueue()
                    toast.error(message, {toastId: 401})

                    // remove locally saved items by logging out
                    users.logout()
                    history.push('/login')
                    break
        //         case 400:
        //             if(error.response.data.errors.title === 'FAILURE_CREDENTIALS_INVALID'){
        //                 toast.error('Incorrect username or password. Please try again')
        //             }
        //             else{
        //                 toast.error(error.response.data.errors.detail)
        //             }
        //
        //             break
                default:
                    break
            }
        }

        return Promise.reject(error)
    })

ReactDOM.render(
    <Suspense fallback="loading">
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <Route path='/login' component={Login}/>
                        <Route exact path='/requestpasswordreset' component={RequestPasswordReset}/>
                        <Route exact path='/resetpassword/:selector/:validator' component={ResetPassword}/>
                        <Route component={Routes}/>
                        <ToastContainer/>
                    </Switch>
                </Router>
            </QueryClientProvider>
        </React.StrictMode>
    </Suspense>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
