import axios, {AxiosResponse} from 'axios'
import qs from 'querystring'
import persistentStore from "../store/persistentStore";
import {IUser} from "../interfaces/IUser";

const userRequests = () => {

    const logout = () => {
        persistentStore.emptyStore()
    }

    const login = async (data: { email: string, password: string }) => {
        const path = `/login`
        const config = {
            headers: {
                'Accept': 'application/json',
            }
        }

        return axios.post(persistentStore.apiUrl + path, qs.stringify(data), config)
            // .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                const serverData = response.data

                if (response.data.success === true) {
                    handleLogin(serverData.data.user, serverData.data.token)
                    return serverData
                }

                console.error(serverData)
            })
    }

    const handleLogin = (user: IUser, token: string) => {
        persistentStore.setUser(user)
        persistentStore.setToken(token)
        persistentStore.setProject(user.projects[0])
    }

    const register = async ({email, password}: { email: string, password: string }) => {
        const path = `/users/register`
        const userData = {email: email, password: password}

        return axios.post(persistentStore.apiUrl + path, userData, persistentStore.authHeader)
            .then((response: AxiosResponse) => {
                if (response.data.success === true) {
                    return response.data.data.user
                }

                throw new Error(response.data)
            })
    }

    const update = async (user: string, id: string) => {
        const path = `/users/edit/${id}`
        const data = user
        return axios.put(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then((response: AxiosResponse) => {
                const data = response.data
                return data
            })
    }

    const requestResetPassword = async (data: any) => {
        const path = `/requestResetPassword`

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    const validatePasswordResetTokens = async (data: { selector: any, validator: any }) => {
        const path = `/validatePasswordResetTokens`

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => response.data)
    };

    const resetPassword = async (data: { password: string, validator: string, selector: string }) => {
        const path = `/resetpassword`

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    return {
        login,
        logout,
        update,
        register,
        requestResetPassword,
        validatePasswordResetTokens,
        resetPassword,
    }
}

export const users = userRequests()
