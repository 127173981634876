import React from 'react';
import { Redirect } from "react-router-dom";
import {observer} from "mobx-react";
import {useParams} from "../../helpers/router";

const RegistrationSuccess = () => {
    const {email}:{email: string} = useParams()

    // fail if user.email is not set (most probably, this url is illegally
    // entered. Visitors should only be coming from the registration page
    if (! email) {
        console.log('No user known, redirecting to home')
        return <Redirect to='/'/>
    }

    return (
        <div id='registration-success'>
            <h1>Registratie succes</h1>
            <p>Je registratie is gelukt. Ik heb een mailtje gestuurd naar {email}.</p>
            <p>Kijk in je mailbox voor een mailtje. Daar staat een link in waarmee je in kunt loggen.</p>
        </div>
    )
}

export default observer(RegistrationSuccess)
