import axios, {AxiosResponse} from 'axios'
import persistentStore from "../store/persistentStore";
import {IMonthResults} from "../interfaces/IMonthResults";

const resultRequests = () => {

    const getAll = async (projectId: string)  => {
        const path = `/results/${projectId}`

        return axios.get(persistentStore.apiUrl+path, persistentStore.authHeader)
            // .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                if(response.data.success){
                    return response.data.data.results
                }

                console.error('Error while getting latest results')
                console.error(response)
            })
    }

    const add = async (result: IMonthResults)  => {
        const path = `/results`

        return axios.post(persistentStore.apiUrl+path, result, persistentStore.authHeader)
            // .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                if(response.data.success){
                    return response.data.data.result
                }

                console.error('Error while addding the result')
                console.error(response)
            })
            .catch((error: any) => {
                console.error('Error while updating the result')
                console.error(error)
            })
    }

    const update = async (result: IMonthResults)  => {
        if(!result.id || result.id === ''){
            console.log(`no result id set: `, result)
            return add(result)
        }

        const path = `/results/${result.id}`

        return axios.put(persistentStore.apiUrl+path, result, persistentStore.authHeader)
            // .then(response => errorChecker(response))
            .then((response: AxiosResponse) => {
                if(response.data.success){
                    return response.data.data.result
                }

                console.error('Error while updating the result')
                console.error(response)
            })
            .catch((error: any) => {
                console.error('Error while updating the result')
                console.error(error)
            })
    }

    return {
        getAll,
        update,
        add
    }
}

export const results = resultRequests()
