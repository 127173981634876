import React from 'react';
import ClearCache from "./pages/ClearCache/ClearCache";
import {Switch, Route} from "./helpers/router";
import App from "./App";
import Register from "./pages/Register/Register";
import RegistrationSuccess from "./pages/Register/RegistrationSuccess";

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/clear-cache' component={ClearCache}/>
            <Route exact path='/register' component={Register}/>
            <Route path='/registration-success/:email' component={RegistrationSuccess}/>
            {/*<Route exact path="/register" component={Register}/>*/}
            {/*<Route exact path="/dashboard" component={Dashboard}/>*/}
            <Route component={App}/>
        </Switch>
    )
}

export default Routes;
