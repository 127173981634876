import React from 'react'
import {observer} from 'mobx-react'

const ResetPassword = () => {
    // const [password, setPassword] = useState('')
    // const [error, setError] = useState('')
    // const [success, setSuccess] = useState('')
    // const [buttonDisabled, setButtonDisabled] = useState(false)
    // const router = useRouter()
    // const {t} = useTranslation()
    // ts-disable-next-line
    // const {selector, validator} = router.query
    //
    // useEffect(() => {
    //     // validate the password reset values
    //     users.validatePasswordResetTokens({selector: selector, validator: validator})
    //         .then(response => {
    //             if(response.success === false){
    //                 setButtonDisabled(true)
    //                 setError('The link is expired. Please request a new password reset link.');
    //             }
    //         })
    //         .catch(error => {
    //             setButtonDisabled(true)
    //             setError(error.response.data.error.message)
    //         })
    // }, [selector, validator])

    // const handlePasswordChange = (event: any) => {
    //     event.preventDefault()
    //
    //     users.resetPassword({password: password, validator: validator, selector: selector})
    //         .then((response) => {
    //             if (response.success !== true) {
    //                 setError(response.error)
    //                 return
    //             }
    //
    //             setSuccess(t('Password successfully changed. Go to the Login page and login using your new password'))
    //         })
    // }

    return (
        <>
            {/*<main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">*/}
            {/*    <form className="form-signin col-md-5" onSubmit={handlePasswordChange}>*/}

            {/*        <h1 className="h3 mb-3 font-weight-normal pt-2">*/}
            {/*            {t('Change your password')}*/}
            {/*        </h1>*/}

            {/*        {error && <Alert variant='danger'>{error}</Alert>}*/}
            {/*        {success && <Alert variant='success'>{success}</Alert>}*/}

            {/*        <FormGroup>*/}
            {/*            <label htmlFor="inputPassword" className="sr-only">{t('Password')}</label>*/}
            {/*            <input type="password" id="inputPassword" className="form-control" placeholder={t("Password")}*/}
            {/*                   name="password" value={password} onChange={e => setPassword(e.target.value)}*/}
            {/*                   disabled={success !== ''}*/}
            {/*                   required*/}
            {/*            />*/}
            {/*        </FormGroup>*/}

            {/*        <button className="btn btn-primary btn-block" type="submit" disabled={success !== '' || buttonDisabled} >*/}
            {/*            /!*{loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}*!/*/}
            {/*            {t('Change password')}*/}
            {/*        </button>*/}

            {/*        <Link to="/login" className="btn btn-link">{t('Login')}</Link>*/}
            {/*    </form>*/}
            {/*</main>*/}
        </>
    )
}

export default observer(ResetPassword)

