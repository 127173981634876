import React, {ChangeEvent, useEffect, useState} from 'react';
import {IMonthResults} from "../../interfaces/IMonthResults";
import uiStore from "../../store/uiStore";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {results as resultsApi} from "../../api/results";
import {Card, Col, Row} from "react-bootstrap";
import PeriodSwitcher from "./PeriodSwitcher";
import SmileyResult from "./SmileyResult";
import FuelFunnel from "./FuelFunnel";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import ResultsTable from "./ResultsTable";
import persistentStore from "../../store/persistentStore";
import Logo from '../../logo.png'
import ProjectSelector from "./ProjectSelector";

const Dashboard = () => {
    const [result, setResult] = useState<IMonthResults>(uiStore.currentResult)
    const [results, setResults] = useState<IMonthResults[]>([])
    const [stringCosts, setStringCosts] = useState<string>(`${result.costs_in_cents}`)
    const [showForm, setShowForm] = useState(false)
    const [update, setUpdate] = useState<any | undefined>()
    const resultsQuery = useQuery(
        ['results', persistentStore.project?.id],
        () => resultsApi.getAll(persistentStore.project?.id || ''),
        {
            enabled: (persistentStore.project !== undefined && persistentStore.user !== undefined)
        }
    )
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const resultMutation = useMutation(
        'mutateResult',
        (updatedResult: IMonthResults) => resultsApi.update(updatedResult),
        {
            onSuccess: (data, variables, contex) => {
                setResult(variables)
            },
            onError: (error, variables, context) => {
                console.error(error)
            },
            onSettled: () => {
                queryClient.invalidateQueries(['results', persistentStore.project?.id])
            }
        }
    )

    useEffect(() => {
        if (resultsQuery.data) {
            setResults(resultsQuery.data)
        }
    }, [resultsQuery.data])

    useEffect(() => {
        const result = results.find((result: IMonthResults) => result.date.startsWith(uiStore.period))

        if (result) {
            setResult(result)

            setStringCosts((result.costs_in_cents / 100).toLocaleString(uiStore.locale, uiStore.moneyNoSymbol))
        } else {
            setResult(uiStore.emptyResults)
            setStringCosts("0")
        }

        // eslint-disable-next-line
    }, [uiStore.period, results])

    const handleAmountChange = (value: string) => {
        // on empty inputfield
        if (value === '') {
            return 0
        }

        // replace the comma by a dot so JS understands it as a float
        const interpreted = parseFloat(value.replace(/,(\d+)$/, '.$1'));

        // check whether it's a valid number
        if (isNaN(interpreted)) {
            return 0
        }

        return interpreted
    }

    const changeValue = (newValue: string, fieldName: string) => {
        const updatedResult = Object.assign({}, result)

        if (fieldName === 'costs_in_cents') {
            updatedResult.costs_in_cents = Math.trunc(handleAmountChange(newValue) * 100)
            setStringCosts(newValue)
        } else if (fieldName === 'impressions') {
            updatedResult.impressions = parseInt(newValue, 10)
        } else if (fieldName === 'clicks') {
            updatedResult.clicks = parseInt(newValue, 10)
        } else if (fieldName === 'leadmagnet_hits') {
            updatedResult.leadmagnet_hits = parseInt(newValue, 10)
        } else if (fieldName === 'frontEnd') {
            updatedResult.fe_sales = parseInt(newValue, 10)
        } else if (fieldName === 'notes') {
            updatedResult.notes = newValue
        }

        updatedResult.date = uiStore.period

        if (!updatedResult['project_id']) {
            updatedResult.project_id = persistentStore.project?.id || ''
        }

        updatedResult.conversion_im_clicks = updatedResult.impressions === 0 ? 0 : updatedResult.clicks / updatedResult.impressions
        updatedResult.conversion_lm_fe = updatedResult.leadmagnet_hits === 0 ? 0 : updatedResult.fe_sales / updatedResult.leadmagnet_hits
        updatedResult.conversion_ads_lm = updatedResult.clicks === 0 ? 0 : updatedResult.leadmagnet_hits / updatedResult.clicks
        updatedResult.cpa = Math.trunc(updatedResult.costs_in_cents / updatedResult.fe_sales)
        updatedResult.cpc = Math.trunc(updatedResult.costs_in_cents / updatedResult.clicks)

        const fePriceInCents = persistentStore.project?.fe_price_in_cents || 0
        updatedResult.roas = (updatedResult.fe_sales * fePriceInCents) / updatedResult.costs_in_cents

        console.log(`roas: ${updatedResult.roas}`)

        // don't be updating to the server right away. Wait at leas 500ms
        update && clearTimeout(update) && setUpdate(undefined)
        const delayedUpdate = setTimeout(
            () => {
                console.log('sending the following to the api:')
                console.log(updatedResult)
                resultMutation.mutate(updatedResult)
            },
            2000
        )
        setUpdate(delayedUpdate)

        setResult(updatedResult)
    }

    const toggleForm = () => {
        const toggledForm = !showForm
        setShowForm(toggledForm)
    }

    const FlexBox = (props: any) => {
        return <div className={props.className} style={{display: 'flex'}}>
            {props.children}
        </div>
    }

    return (
        <>
            <div className='container'>
                <FlexBox>
                    <div className='mr-2'>
                        <img src={Logo} alt='brandstoffunnel-logo' width='50px'/>
                    </div>
                    <div>
                        <h1>Funnel Monitor</h1>
                    </div>
                </FlexBox>

                <ProjectSelector className='mt-4'/>
                <PeriodSwitcher className='mt-4'/>

                <Card className='mt-5'>
                    <Card.Header onClick={toggleForm} style={{cursor: 'pointer'}}>
                        {t('Change results')}
                    </Card.Header>
                    <Card.Body style={{display: showForm ? 'block' : 'none'}}>
                        <form>
                            <div className="form-group row">
                                <label htmlFor="adCosts" className="col-sm-2 col-form-label">
                                    Advertentiekosten
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="adCosts"
                                        placeholder=""
                                        onChange={(event: any) => changeValue(event.target.value, 'costs_in_cents')}
                                        value={stringCosts}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="adClicks" className="col-sm-2 col-form-label">
                                    Advertentie impressies (vertoningen)
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="adImpressions"
                                        placeholder=""
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => changeValue(event.target.value, 'impressions')}
                                        value={result.impressions || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="adClicks" className="col-sm-2 col-form-label">
                                    Advertentie clicks
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="adClicks"
                                        placeholder=""
                                        onChange={(event: any) => changeValue(event.target.value, 'clicks')}
                                        value={result.clicks || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="lm" className="col-sm-2 col-form-label">
                                    LeadMagnet downloads/trials
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lm"
                                        placeholder=""
                                        onChange={(event: any) => changeValue(event.target.value, 'leadmagnet_hits')}
                                        value={result.leadmagnet_hits || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="fe" className="col-sm-2 col-form-label">
                                    FrontEnd verkopen/sales
                                </label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fe"
                                        placeholder=""
                                        onChange={(event: any) => changeValue(event.target.value, 'frontEnd')}
                                        value={result.fe_sales || ''}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="fe" className="col-sm-2 col-form-label">
                                    Notities
                                </label>
                                <div className="col-sm-10">
                                <textarea
                                    className="form-control"
                                    id="notes"
                                    placeholder=""
                                    onChange={(event: any) => changeValue(event.target.value, 'notes')}
                                    value={result.notes || ''}
                                />
                                </div>
                            </div>
                        </form>

                    </Card.Body>
                </Card>

                <Row className='mt-5'>
                    <Col sm={2}>
                        <SmileyResult results={result}/>
                    </Col>
                    <Col>
                        <FuelFunnel results={result}/>
                    </Col>
                </Row>
            </div>

            <div className='m-3 mt-5'>
                <ResultsTable results={results}/>
            </div>
        </>
    );
}

export default observer(Dashboard);
