import {makeAutoObservable} from 'mobx'
import autoStore from './autoStore'
import {IUser} from "../interfaces/IUser";
import {IProject} from "../interfaces/IProject";
class PersistentStore {
    apiUrl: string = 'http://localhost:8080'
    token: string | undefined
    user: IUser | undefined
    project: IProject | undefined

    constructor() {
        makeAutoObservable(this)
        // store changes to the state in localStorage
        autoStore(this, 'persistentStore')
    }

    emptyStore(){
        this.token = undefined
        this.user = undefined
        this.project = undefined
    }

    setProject(newProject: IProject){
        this.project = newProject
    }

    setUser(newUser: IUser | undefined) {
        this.user = newUser
    }

    logout() {
        this.token = ''
    }

    get isLoggedIn(){
        return this.user !== undefined
    }

    setApiUrl(apiUrl: string) {
        this.apiUrl = apiUrl
    }

    get isAdmin(){
        return this.user?.is_admin === true
    }

    setToken(token: string | undefined) {
        this.token = token
    }

    get authHeader() {
        return {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'bearer ' + this.token
            }
        }
    }
}

const persistentStore = new PersistentStore()
export default persistentStore
