import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {projects as projectsApi} from "../../api/projects";
import {IProject} from "../../interfaces/IProject";
import persistentStore from "../../store/persistentStore";

type ProjectSelectorPROPS = {
    className?: string
}

const ProjectSelector = (props: ProjectSelectorPROPS) => {
    const projectQuery = useQuery(
        'getProjects',
        projectsApi.getAll,
        {
            refetchInterval: false,
        }
    )
    const [projects, setProjects] = useState<IProject[]>([])

    useEffect(() => {
        if(projectQuery.data){
            setProjects(projectQuery.data)

            // set the first project as default when one was not selected already
            if(persistentStore.project === undefined) {
                persistentStore.setProject(projectQuery.data[0])
            }
        }
    }, [projectQuery.data])

    const changeSelectedProject = (event: any) => {
        const selectedProjectId = event.target.value
        const selectedProject = projects.find((project: IProject) => project.id === selectedProjectId)
        console.log(`Changing project to: ${selectedProject?.name}`)

        if(! selectedProject){
            throw new Error('Selected project was not inside the received projects from the server')
        }

        persistentStore.setProject(selectedProject)
    }

    return (
        <select className={props.className} onChange={changeSelectedProject} value={persistentStore.project?.id}>
            {projects.map((project: IProject, index: number) => (
                <option key={index} value={project.id}>
                    {project.name}
                </option>
            ))}
        </select>
    )
};

export default ProjectSelector;