import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {users} from "../../api/users";
import {useRouter} from "../../helpers/router";
import {Alert} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import persistentStore from "../../store/persistentStore";
import {toast} from "react-toastify";

export const Login = () => {
    const {t} = useTranslation()
    const router = useRouter()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // clear
        persistentStore.setUser(undefined)
        persistentStore.setToken(undefined)
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault()

        // reset the errormessage
        setError(undefined)

        users.login({email: email, password: password})
            .then(result => {
                setLoading(false)
                if (result.success === true) {
                    router.push('/dashboard')
                } else {
                    setError(t('Login failed'))
                }
            })
            .catch((errorResponse: any) => {
                const error = errorResponse.resposne.data.errors

                if(error.title === 'FAILURE_CREDENTIALS_INVALID'){
                    toast.error(t('Incorrect username or password. Please try again'))
                }
            })
    }

    return (
        <form className="form-signin container" onSubmit={(e) => handleSubmit(e)}>
            <h2 className="form-signin-heading">{t('Log in now')}</h2>
            {error && (
                <Alert variant='danger'>{error}</Alert>
            )}
            <div className="login-wrap">
                <input
                    type="email"
                    className="form-control"
                    placeholder={t("User ID")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                />

                <input
                    type="password"
                    className="form-control"
                    placeholder={t("Password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <label className="checkbox">
                    <input type="checkbox" value="remember-me"/> {t('Remember me')}&nbsp;
                    <span className="pull-right">
                        | <Link to="/passwordreset">{t('Forgot Password?')}</Link>&nbsp;
                        | <Link to="/register">{t('Register for free')}</Link>
                    </span>
                </label>

                <button className="btn btn-lg btn-login btn-block btn-primary mt-2" type="submit">
                    {loading && <Spinner animation="border"/>}
                    {!loading && t('Log in')}
                </button>
                {/*<div className="registration">*/}
                {/*    {t("Don't have an account yet?")}&nbsp;*/}
                {/*    <a className="" href="registration.html">*/}
                {/*        {t('Create an account')}*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>

            <div aria-hidden="true" aria-labelledby="myModalLabel" role="dialog" tabIndex={-1} id="myModal"
                 className="modal fade">
                <div className="modal-dialog">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Forgot Password?')}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <p>{t('Enter your e-mail address below to reset your password')}.</p>
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                autoComplete="off"
                                className="form-control placeholder-no-fix"
                            />
                        </div>

                        <div className="modal-footer">
                            <button
                                data-dismiss="modal"
                                className="btn btn-default"
                                type="button"
                            >
                                {t('Cancel')}
                            </button>

                            <button className="btn btn-success" type="button">{t('Submit')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
